import React, { useEffect } from "react"
import { Link } from "gatsby"
// Layout
import LayoutComponent from "../components/LayoutComponent"
// blocks main page
import BannerBlock from "../blocks/main-page/banner.block"
import NewsBlock from "../blocks/main-page/news.block"
import LocationBlock from "../blocks/main-page/location.block"
import NewHome from "./new-home"
import { Seo } from "../components/seo"
import { Helmet } from "react-helmet"
export interface IndexPageProps {}

const IndexPage: React.SFC<IndexPageProps> = () => {
  /*useEffect(() => {
    const HEADER_ELEMENT = document.querySelector(".header-mobile")
    const HEADER_STICKY = document.querySelector(".container-menu-stick")
    const mediaQuery = window.matchMedia("(max-width: 767px)").matches
    if (!mediaQuery) {
      window.addEventListener("scroll", e => {
        if (document.documentElement.scrollTop > 30) {
          HEADER_STICKY.classList.add("nav-bar-scroll")
        } else {
          HEADER_STICKY.classList.remove("nav-bar-scroll")
        }
      })
    } else {
      HEADER_ELEMENT?.classList.remove("nav-bar-scroll")
      window.addEventListener("scroll", e => {
        if (document.documentElement.scrollTop > 500) {
          HEADER_ELEMENT?.classList.add("change-backgorund")
        } else {
          HEADER_ELEMENT?.classList.remove("change-backgorund")
        }
      })
    }
  })*/
  return (
    <>
      <Seo title="Super Sonic Design INC | 会社・業務内容について"
        url="https://supersonicdesign.com/"
        description="弊社の業務内容や代表の鹿毛の起業した際のストーリーなどを動画でお話させていただきました。"
        image = ""
        author = ""
        keywords = "Super Sonic Design INC | 会社・業務内容について" />
      <NewHome/>
      {
      /* OLD COMPONENT
      <LayoutComponent>
        <BannerBlock />
        <NewsBlock />
        <LocationBlock />
      </LayoutComponent>*/}
    </>
  )
}

export default IndexPage
// const IndexPage = () => (

// )

// export default IndexPage
